<template>
    <div class="board__list">
        <div class="mb-20 mb-lg-40">
            <v-row align="center" justify="center" class="row--x-small flex-column flex-md-row">
                <v-col cols="auto" class="mb-2 mb-md-0">
                    <board-user-level :user="user" large noneActive textStyle="tit tit--lg line-height-1 font-weight-bold" />
                </v-col>
                <v-col cols="auto">
                    <h2 class="tit tit--lg line-height-1 ml-md-2">회원님의 작성 게시물</h2>
                </v-col>
            </v-row>
        </div>

        <div class="mb-20 mb-lg-40">
            <table class="board-list board-list--main">
                <thead class="d-none d-lg-table-header">
                    <tr>
                        <th style="width:100px;">카테고리</th>
                        <th style="width:100px;">카테고리</th>
                        <th style="width:48%;">제목</th>
                        <th style="width:12%;">작성자</th>
                        <th style="width:11%;">작성일자</th>
                        <th style="width:11%;">조회수</th>
                        <th style="width:11%;">댓글수</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="board in boards" :key="board._id" @click="move(board)">
                        <td align="center" class="board-list__txt board-list__txt--first">
                            <span v-if="board?.category?.parents?.code === ('column' || 'topic')" class="grey-6--text">{{ board?.category?.parents?.name }}</span>
                            <span v-else class="grey-6--text">커뮤니티</span>
                        </td>
                        <td align="center" class="board-list__txt">
                            <v-chip color="primary-50" text-color="primary">
                                {{ board?.category?.name }}
                            </v-chip>
                        </td>
                        <td align="center" class="board-list__tit">
                            <div class="board-list__tit">
                                <p class="grey-6--text page-text ellip">
                                    {{ board?.subject }}
                                </p>
                            </div>
                        </td>
                        <td align="center" class="board-list__txt">
                            <div class="d-flex h-100 align-center justify-center">
                                <board-user-level :user="board?.user" noneActive textStyle="page-text--sm grey-9--text" />
                            </div>
                        </td>
                        <td align="center" class="board-list__txt">
                            <span class="page-text page-text--sm">{{ $dayjs(board?.createdAt).format("YYYY.MM.DD") }}</span>
                        </td>
                        <td align="center" class="board-list__txt">
                            <div class="d-flex align-center" :class="{ 'justify-end': justifyEnd, 'justify-center': !justifyEnd }">
                                <i class="icon icon-view mr-4 mr-lg-8"></i>
                                <p class="page-text--sm grey-9--text">
                                    {{ board?.viewCount?.format() }}
                                </p>
                            </div>
                        </td>
                        <td align="center" class="board-list__txt">
                            <board-count-comment :commentCount="board?.commentCount"/>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>

        <v-row no-gutters>
            <v-col cols="auto">
                <v-row class="row--x-small search--primary">
                    <v-spacer></v-spacer>
                    <v-col cols="12" md="auto">
                        <v-select v-model="filter.searchKey" :items="searchKeys" class="v-input--small w-100 w-md-120px" outlined hide-details @change="search(true)"></v-select>
                    </v-col>
                    <v-col cols="9" md="auto">
                        <v-text-field v-model="filter.searchValue" class="v-input--small w-100 w-md-240px" outlined hide-details placeholder="검색어를 입력하세요." :disabled="!filter.searchKey" @input="search(true)"></v-text-field>
                    </v-col>
                    <v-col cols="3" md="auto">
                        <v-btn class="h-100 w-100 min-w-md-60px" color="grey-6">
                            <span class="white--text font-size-14" @click="search()">검색</span>
                        </v-btn>
                    </v-col>
                </v-row>
            </v-col>
        </v-row>

        <div class="v-pagination-wrap">
            <v-pagination v-model="page" :length="pageCount" :total-visible="11" class="mt-4 mb-12"></v-pagination>
        </div>
    </div>
</template>

<script>
import api from "@/api";
import BoardUserLevel from "@/components/client/board/board-user-level.vue";
import SearchPrimary from "@/components/client/board/search--primary.vue";
import BoardCountView from "@/components/client/board/board-count--view.vue";
import BoardCountComment from "@/components/client/board/board-count--comment.vue";

export default {
    props: {
        justifyEnd: { type: Boolean, default: false },
    },

    components: {
        BoardUserLevel,
        SearchPrimary,
        BoardCountView,
        BoardCountComment,
    },

    data() {
        return {
            page: 1,
            pageCount: 0,
            limit: 10,

            user: {},
            boards: [],
            filter: {
                searchKey: this.$route.query.searchKey || null,
                searchValue: this.$route.query.searchValue || null,
            },

            searchKeys: [
                { text: ":: 전체 ::", value: null },
                { text: "제목 + 내용", value: "subjectcontent" },
                { text: "제목", value: "subject" },
            ],
        };
    },

    async mounted() {
        await this.init();
    },
    methods: {
        async init() {
            await this.search();
        },
        async search(routable = false) {
            if (routable) {
                this.$router.push({
                    query: { ...this.filter, page: this.page },
                });
                return;
            }

            var { summary, boards } = await api.v1.boards.users.gets({
                headers: {
                    skip: (this.page - 1) * this.limit,
                    limit: this.limit,
                },
                params: this.filter,
                _id: this._user,
            });

            this.user = boards[0].user;

            this.boards = boards;
            this.summary = summary;
            this.pageCount = Math.ceil(summary.totalCount / this.limit);
        },
        move(board) {
            switch (board?.category?.parents?.code) {
                case "column":
                    this.$router.push(`/content/column/${board?._id}`);
                    break;
                case "topic":
                    this.$router.push(`/information/topic/${board?._id}`);
                    break;

                default:
                    this.$router.push(`/community/board/${board?._id}`);
                    break;
            }
        },
    },

    computed: {
        _user() {
            return this.$route.params._user;
        },
    },
};
</script>

<style lang="scss" scoped>
.v-menu__content {
    background-color: transparent;
    box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.1);
    border-radius: 6px;
    transform: translate(20px, 2px);
}
</style>
