<template>
    <client-page class="app--main">
        <section class="section section--first section--last">
            <v-container>

                <content-head />

                <div class="mb-20 mb-lg-30">
                    <v-row align="center" class="row--x-small">
                        <v-spacer />
                        <v-col cols="auto">
                            <survey-filter :categories="categories" @apply="setFilter"/>
                        </v-col>
                    </v-row>
                </div>

                <v-row class="my-n10 my-lg-n30">
                    <v-col v-for="survey in surveys" :key="survey._id" cols="12" sm="3" class="py-10 py-lg-30">
                        <survey-item-primary :survey="survey"/>
                    </v-col>
                </v-row>

                <v-divider class="mt-30 mt-lg-60 mb-20 mb-lg-40"/>

                <v-row class="row--x-small search--primary">
                    <v-col cols="12" md="auto">
                        <v-select v-model="filter.searchKey" :items="searchKeys" class="v-input--small w-100 w-md-120px" outlined hide-details></v-select>
                    </v-col>
                    <v-col cols="9" md="auto">
                        <v-text-field v-model="filter.searchValue" class="v-input--small w-100 w-md-240px" outlined hide-details placeholder="검색어를 입력하세요."/>
                    </v-col>
                    <v-col cols="3" md="auto">
                        <v-btn class="h-100 w-100 min-w-md-60px" color="grey-6" @click="search()">
                            <span class="white--text font-size-14">검색</span>
                        </v-btn>
                    </v-col>
                </v-row>

                <div class="v-pagination-wrap">
                    <v-pagination v-model="page" :length="pageCount" :total-visible="11" @input="search()"></v-pagination>
                </div>

            </v-container>
        </section>
    </client-page>
</template>

<script>
import api from '@/api';
import ClientPage from "@/pages/client/templates/ClientPage.vue";
import ContentHead from "@/pages/client/content/ContentHead.vue";
import SurveyFilter from "@/components/client/survey/survey-filter.vue";
import SurveyItemPrimary from "@/components/client/survey/survey-item--primary.vue";

export default {
    components: {
        ClientPage,
        ContentHead,
        SurveyFilter,
        SurveyItemPrimary,
    },

    data() {
        return {
            page: 1,
            limit: 8,
            pageCount: 0,
            surveys: [],
            categories: [],

            filter: {
                searchKey: "subject",
                searchValue: null,
                category: null,
                sortKey: null
            },

            searchKeys: [
                { text: "제목", value: "subject" },
            ],
        }
    },
    async mounted() {
        await this.init();
    },
    methods: {
        async init() {

            let { categories } = await api.v1.surveys.categories.gets({ params: { depth: 1 } })
            this.categories = categories;
            await this.search();
        },
        async search() {
            let { summary, surveys } = await api.v1.surveys.gets({
                headers: {
                    skip: (this.page - 1) * this.limit,
                    limit: this.limit
                },
                params: { ...this.filter }
            });
            this.pageCount = Math.ceil(summary.totalCount / this.limit);
            this.surveys = surveys;
        },
        async setFilter({ name, sortKey }) {
            this.filter.category = name;
            this.filter.sortKey = sortKey;
            this.page = 1;
            await this.search();
        }
    }
};
</script>
