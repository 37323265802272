<template>
    <client-page class="app--main">
        <recover-view />
    </client-page>
</template>

<script>
import ClientPage from "@/pages/client/templates/ClientPage.vue";
import RecoverView from "@/components/client/recover/recover-view.vue";

export default {
    components: {
        ClientPage,
        RecoverView,
    },
};
</script>
