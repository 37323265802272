<template>
    <client-page class="app--main">
        <section class="section section--last">
            <v-container v-if="result">

                <!-- 결과1 -->
                <div v-if="!result?.content" class="d-flex flex-column align-center text-center">
                    <h2 class="tit tit--lg line-height-1 primary--text">{{ result?.survey?.subject }} 설문조사</h2>
                    <i class="icon icon-check icon-check--fill mt-20 mt-lg-44" />
                    <h3 class="tit grey-6--text mt-20 mt-lg-40">설문에 참여해 주셔서 감사합니다.</h3>
                    <v-btn to="/content/survey" exact large color="primary" class=" mt-20 mt-lg-40">다른 설문조사 하러가기</v-btn>
                </div>
                <div v-else class="d-flex flex-column align-center text-center">
                    <h2 class="tit tit--lg line-height-1 primary--text">{{ result?.survey?.subject }} 설문조사</h2>
                    <!-- <v-img src="/images/test2.jpg" max-width="520" :aspect-ratio="520 / 668" class="w-100 mt-40 mt-lg-80" /> -->
                    <p v-html="result?.content"  max-width="520" :aspect-ratio="520 / 668" class="w-100 mt-40 mt-lg-80"/>
                    <v-btn to="/content/survey" exact large color="primary" class=" mt-40 mt-lg-80">다른 설문조사 하러가기</v-btn>
                </div>

            </v-container>
        </section>
    </client-page>
</template>

<script>
import api from "@/api"
import ClientPage from "@/pages/client/templates/ClientPage.vue";

export default {
    components: {
        ClientPage,
    },

    async mounted() {
        await this.init();
    },

    data() {
        return {
            result: null
        }
    },

    methods: {
        async init() {
            let { result } = await api.v1.surveys.result.get({ _id: this.$route.params._result });
            console.log(result);
            this.result = result;
        }
    }
};
</script>
