<template>
    <head-tab tit="콘텐츠">
        <v-tabs color="grey-9" hide-slider class="v-tabs--punch">
            <v-tab to="/content" exact>All</v-tab>
            <v-tab to="/content/solution">영상</v-tab>
            <v-tab to="/content/column">칼럼</v-tab>
            <v-tab to="/content/survey">설문조사</v-tab>
            <v-tab to="/content/test">테스트</v-tab>
        </v-tabs>
    </head-tab>
</template>

<script>
import HeadTab from "@/components/client/sub/head-tab.vue";
export default {
    components: {
        HeadTab,
    },
}
</script>

<style>

</style>