<template>
    <client-page class="app--main">
        <section class="section section--first section--last">
            <v-container>
                <div class="tit-wrap text-center">
                    <h2 class="tit tit--lg">
                        <span>{{ survey?.subject }}</span> 설문조사
                    </h2>
                </div>
                <v-tabs-items v-model="tab" touchless>
                    <v-tab-item>
                        <survey-question-view :childrens="node.children" :node="node" @updateNode="answerNext"/>
                    </v-tab-item>
                </v-tabs-items>
                <div class="mt-20 mt-lg-40">
                    <v-row class="row--small" justify="center">
                        <v-col cols="auto">
                            <v-btn icon color="grey-6" @click="prev">
                                <i class="icon icon-left-arrow"></i>
                            </v-btn>
                        </v-col>
                        <v-col cols="auto">
                            <v-btn icon color="grey-6">
                                <i class="icon icon-right-arrow"></i>
                            </v-btn>
                        </v-col>
                    </v-row>
                </div>
            </v-container>
        </section>
    </client-page>
</template>

<script>
import api from "@/api";
import ClientPage from "@/pages/client/templates/ClientPage.vue";
import SurveyQuestionView from "@/components/client/survey/survey-questions-view.vue";

export default {
    components: {
        ClientPage,
        SurveyQuestionView,
    },

    data() {
        return {
            page: 1,
            limit: 6,
            pageCount: 0,

            tab: 0,
            survey: null,
            surveyResult: {
                selectedAnswers: [],

                _survey: this.$route.params._survey,
                label: null,
                content: null,
                hashTag: null
            },
			node: {},
            questionNodeList: [],

            type: "number",
            number: 0,
            duration: 0,
            offset: 0,
            easing: "easeInOutCubic",

            show: false,
        };
    },

    async mounted() {
        if(!this.logon){
            alert("로그인 이후에 사용가능합니다");
            this.$router.push(`/login`);
            return;
        }
        await this.init();
    },

    methods: {
        async init() {
            if (this.$route.params._survey) {
                let { survey } = await api.v1.surveys.get({ _id: this.$route.params._survey });
                this.survey = survey;
				this.node = survey.node;
            }
        },

        async answerNext(node) {
            if (node.children.length === 0) {
                this.surveyResult.selectedAnswers.push(node);
                await this.save();
            }

            if (node.children[0].type === "result") {
                this.surveyResult.label = node.children[0].label;
                this.surveyResult.content = node.children[0].content;
                this.surveyResult.hashTag = node.children[0].hashTag;
                this.surveyResult.selectedAnswers.push(node);
                await this.save();
            } else {
                this.surveyResult.selectedAnswers.push(node);
                this.questionNodeList.push(this.node);
                this.node = node.children[0]
                this.scrollTop();
            }
        },

        async save() {
            let { result } = await api.v1.surveys.result.post(this.surveyResult);
            this.$router.push(`/content/survey/result/${result._id}`);
        },

        async next() {
            if (this.tab === this.surveyResult.selectedAnswers.length) return alert("선택지를 선택해주세요");
            if (this.tab !== this.survey?.questions.length - 1) {
                this.tab++;
                this.scrollTop();
            }
        },

        prev() {
            if (this.questionNodeList.length !== 0) {
                let node = this.questionNodeList.pop();
                this.node = node;
                this.scrollTop();
            }
        },

        scrollTop() {
            this.$vuetify.goTo(this.target, this.options);
        },

        setColor(themeColor) {
            return themeColor.hexa;
        },
    },
    computed: {
        target() {
            const value = this[this.type];
            if (!isNaN(value)) return Number(value);
            else return value;
        },
        options() {
            return {
                duration: this.duration,
                offset: this.offset,
                easing: this.easing,
            };
        },
        accessToken(){
            return this.$store.state.accessToken;
        },
        logon(){ 
            return !!this.accessToken 
        },
    },
};
</script>
