<template>
    <section class="section section--first section--last" id="qna">
        <v-container>
            <h3 class="tit mb-16">QnA</h3>
            <v-divider />

            <!-- S: 질문등록 -->
            <div class="pa-12 pa-lg-20 grey-f7">
                <v-checkbox v-model="question.isPassword" hide-details label="비밀 글" class="mb-16"/>
                <v-row class="row--x-small" align="center">
                    <v-col>
                        <v-textarea v-model="question.content" rows="4" placeholder="질문을 입력하세요." outlined hide-details/>
                    </v-col>
                    <v-col cols="auto">
                        <v-btn small text color="grey-1" @click="save">
                            등록하기
                        </v-btn>
                    </v-col>
                </v-row>
            </div>
            <!-- E: 질문등록 -->

            <v-divider />

            <!-- S: 질문 -->
            <div v-for="question in questions" :key="question._id">
                <div class="pa-12 pa-lg-20">
                    <!-- S: 질문내용 -->
                    <v-row>
                        <v-col cols="auto">
                            <div class="qna__mark accent">Q</div>
                        </v-col>
                        <v-col>
                            <v-card rounded color="grey-fa">
                                <div class="pa-12 pa-lg-20">
                                    <v-row align="center" class="row--x-large">
                                        <v-col>
                                            <v-row class="row--x-small">
                                                <v-col cols="auto">
                                                    <board-user-level :user="question?.user" textStyle="page-text grey-6--text" />
                                                </v-col>
                                                <v-col cols="auto">
                                                    <p class="page-text grey-6--text">{{ $dayjs(question?.createdAt).format("YYYY. MM. DD")  }}</p>
                                                </v-col>
                                            </v-row>
                                            <p v-html="question?.content" class="page-text line-height-15 mt-10" />
                                        </v-col>
                                        <v-col v-if="!question?.reply" cols="12" sm="auto">
                                            <div class="pa-md-24">
                                                <v-btn x-small rounded dark color="grey-6" class="font-weight-bold" @click="setQuestion(question)">답변하기</v-btn>
                                            </div>
                                        </v-col>
                                    </v-row>
                                </div>
                            </v-card>
                        </v-col>
                    </v-row>
                    <!-- E: 질문내용 -->
                    <!-- S: 답변내용 -->
                    <div v-if="question.reply" class="mt-6">
                        <v-row>
                            <v-col cols="auto">
                                <div class="qna__mark primary">A</div>
                            </v-col>
                            <v-col>
                                <v-card rounded color="primary-50">
                                    <div class="pa-12 pa-lg-20">
                                        <v-row class="row--x-small">
                                            <v-col cols="auto">
                                                <board-user-level :user="question?.recover" textStyle="page-text grey-6--text" />
                                            </v-col>
                                            <v-col cols="auto">
                                                <p class="page-text grey-6--text">{{ $dayjs(question?.createdAt).format("YYYY. MM. DD") }}</p>
                                            </v-col>
                                        </v-row>
                                        <p v-html="question?.reply" class="page-text line-height-15 mt-10" />
                                    </div>
                                </v-card>
                            </v-col>
                        </v-row>
                    </div>
                    <!-- E: 답변내용 -->
                </div>
                <!-- S: 답변등록 -->
                <div v-if="question._id === editQuestion._id">
                    <v-divider />
                    <div class="pa-12 pa-lg-20 grey-f7">
                        <v-checkbox v-model="editQuestion.isPassword" hide-details label="비밀 글" class="mb-16"/>
                        <v-row class="row--x-small" align="center">
                            <v-col>
                                <v-textarea v-model="editQuestion.reply" rows="4" placeholder="답변을 입력하세요." outlined hide-details/>
                            </v-col>
                            <v-col cols="auto">
                                <v-btn small text color="grey-1" @click="edit">
                                    등록하기
                                </v-btn>
                            </v-col>
                        </v-row>
                    </div>
                </div>
                <!-- E: 답변등록 -->
                <v-divider />
            </div>
            <!-- E: 질문 -->
            <div class="v-pagination-wrap">
                <v-pagination v-model="page" :length="pageCount" :total-visible="11" @input="init"/>
            </div>
        </v-container>
    </section>
</template>

<script>
import api from "@/api"
import BoardUserLevel from "@/components/client/board/board-user-level.vue";
export default {
    components: {
        BoardUserLevel,
    },
    data() {
        return {
            page: 1,
            limit: 10,
            pageCount: 0, 
            questions: [],
            question: {
                _recover: this.$route.params._recover,
                content: null,
                isPassword: false
            },
            editQuestion: {
                _id: null,
                content: null,
                isPassword: false,
                reply: null
            },
        }
    },
    async mounted() {
        await this.init()
    },
    methods: {
        async init() {
            await this.search()
        },
        async search(){
            let { summary, questions } = await api.v1.users.questions.gets({
                headers: {
                    skip: (this.page - 1) * this.limit,
                    limit: this.limit
                },
                params: { _recover: this.$route.params._recover }
            });

            this.questions = questions;
            this.summary = summary;
            this.pageCount = Math.ceil(summary.totalCount / this.limit);
            this.$emit("totalCount", summary.totalCount)
        },
        async save() {
            let { question } = await api.v1.users.questions.post(this.question);
            this.question.content = null
            this.question.isPassword = false
            alert("질문이 등록되었습니다")
            await this.init();
        },
        async edit() {
            await api.v1.users.questions.put(this.editQuestion);
            alert("답변이 등록되었습니다")
            this.editQuestion._id = null
            this.editQuestion.content = null
            this.editQuestion.isPassword = null
            this.editQuestion.reply = null
            await this.init();
        },
        setQuestion(question) {
            this.editQuestion._id = question._id
            this.editQuestion.content = question.content
            this.editQuestion.isPassword = question.isPassword
            this.editQuestion.reply = question.reply
        }
    },
    computed: {

    }
}
</script>

<style lang="scss" scoped>
::v-deep{
    .v-input--checkbox .v-label {
        font-size: 1.4rem;
        color: #666;
    }
}
.v-application .rounded {
    border-radius: 12px !important;
}
.qna{
    &__mark{
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        border-radius: 50%;
        width: 32px;
        height: 32px;
        font-size: 1.6rem;
        font-weight: 700;
        color: #fff;
    }
}
@media (min-width:576px){
}
@media (min-width:768px){
}
@media (min-width:1024px){
    ::v-deep{
        .v-input--checkbox .v-label {
            font-size: 1.6rem;
        }
    }
    .v-application .rounded {
        border-radius: 24px !important;
    }
    .qna{
        &__mark{
            width: 40px;
            height: 40px;
            font-size: 2rem;
        }
    }
}
@media (min-width:1200px){
}

</style>