var render = function render(){
  var _vm$survey;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('client-page', {
    staticClass: "app--main"
  }, [_c('section', {
    staticClass: "section section--first section--last"
  }, [_c('v-container', [_c('div', {
    staticClass: "tit-wrap text-center"
  }, [_c('h2', {
    staticClass: "tit tit--lg"
  }, [_c('span', [_vm._v(_vm._s((_vm$survey = _vm.survey) === null || _vm$survey === void 0 ? void 0 : _vm$survey.subject))]), _vm._v(" 설문조사 ")])]), _c('v-tabs-items', {
    attrs: {
      "touchless": ""
    },
    model: {
      value: _vm.tab,
      callback: function ($$v) {
        _vm.tab = $$v;
      },
      expression: "tab"
    }
  }, [_c('v-tab-item', [_c('survey-question-view', {
    attrs: {
      "childrens": _vm.node.children,
      "node": _vm.node
    },
    on: {
      "updateNode": _vm.answerNext
    }
  })], 1)], 1), _c('div', {
    staticClass: "mt-20 mt-lg-40"
  }, [_c('v-row', {
    staticClass: "row--small",
    attrs: {
      "justify": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-btn', {
    attrs: {
      "icon": "",
      "color": "grey-6"
    },
    on: {
      "click": _vm.prev
    }
  }, [_c('i', {
    staticClass: "icon icon-left-arrow"
  })])], 1), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-btn', {
    attrs: {
      "icon": "",
      "color": "grey-6"
    }
  }, [_c('i', {
    staticClass: "icon icon-right-arrow"
  })])], 1)], 1)], 1)], 1)], 1)]);

}
var staticRenderFns = []

export { render, staticRenderFns }