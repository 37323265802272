var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('head-tab', {
    attrs: {
      "tit": "콘텐츠"
    }
  }, [_c('v-tabs', {
    staticClass: "v-tabs--punch",
    attrs: {
      "color": "grey-9",
      "hide-slider": ""
    }
  }, [_c('v-tab', {
    attrs: {
      "to": "/content",
      "exact": ""
    }
  }, [_vm._v("All")]), _c('v-tab', {
    attrs: {
      "to": "/content/solution"
    }
  }, [_vm._v("영상")]), _c('v-tab', {
    attrs: {
      "to": "/content/column"
    }
  }, [_vm._v("칼럼")]), _c('v-tab', {
    attrs: {
      "to": "/content/survey"
    }
  }, [_vm._v("설문조사")]), _c('v-tab', {
    attrs: {
      "to": "/content/test"
    }
  }, [_vm._v("테스트")])], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }