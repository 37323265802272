<template>
    <client-page class="app--main">
        <section class="section section--first section--last">
            <v-container>
                <recover-list />
            </v-container>
        </section>
    </client-page>
</template>

<script>
import ClientPage from "@/pages/client/templates/ClientPage.vue";
import RecoverList from "@/components/client/recover/recover-list.vue";

export default {
    components: {
        ClientPage,
        RecoverList,
    },
};
</script>
