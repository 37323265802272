var render = function render(){
  var _vm$survey, _vm$survey2, _vm$survey3, _vm$survey4;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "board-item",
    on: {
      "click": _vm.move
    }
  }, [_c('v-card', {
    staticClass: "mb-16",
    attrs: {
      "rounded": ""
    }
  }, [_c('div', {
    staticClass: "thumb"
  }, [_c('div', {
    staticClass: "thumb__inner",
    style: `background-image: url(${(_vm$survey = _vm.survey) === null || _vm$survey === void 0 ? void 0 : _vm$survey.thumb})`
  })])]), _c('v-row', {
    staticClass: "row--x-small"
  }, [_c('v-col', [_c('v-chip', {
    staticClass: "mr-4 mr-lg-8",
    attrs: {
      "color": "primary-50",
      "text-color": "primary"
    }
  }, [_vm._v(" " + _vm._s((_vm$survey2 = _vm.survey) === null || _vm$survey2 === void 0 ? void 0 : _vm$survey2.category) + " ")])], 1), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('board-count', {
    attrs: {
      "viewCount": (_vm$survey3 = _vm.survey) === null || _vm$survey3 === void 0 ? void 0 : _vm$survey3.viewCount,
      "survey": true
    }
  })], 1)], 1), _c('p', {
    staticClass: "tit tit--sm font-weight-medium ellip mt-8 mt-lg-16"
  }, [_vm._v(" " + _vm._s((_vm$survey4 = _vm.survey) === null || _vm$survey4 === void 0 ? void 0 : _vm$survey4.subject) + " ")])], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }