<template>
    <client-page>
        <side-banner code="community">
            <main-banner class="section section--first pb-0" />
            <section class="section section--last">
                <board-list />
            </section>
        </side-banner>
    </client-page>
</template>

<script>
import ClientPage from "@/pages/client/templates/ClientPage.vue";
import MainBanner from "@/components/client/main/main-banner.vue";
import SideBanner from "@/components/client/inc/side-banner.vue";
import BoardList from "@/components/client/board/skin/community/list.vue";

export default {
    props: {
    },
    components: {
        ClientPage,
        MainBanner,
        SideBanner,
        BoardList,
    },
};
</script>

<style lang="scss" scoped>
</style>