<template>
    <client-page>
        <section class="section--first section--last">
            <v-container>

                <content-head />

                <div class="mb-20 mb-lg-30">
                    <v-row class="row--x-small">
                        <v-spacer />
                        <v-col cols="auto">
                            <v-select v-model="filter.sortKey" :items="sortKeys" class="v-input--small w-100px" outlined hide-details @change="init()"></v-select>
                            <!-- <v-select v-model="filter.sortKey" :items="sortKeys" class="v-input--small w-100px" outlined hide-details @change="search()"></v-select> -->
                        </v-col>
                    </v-row>
                </div>

                <!-- S: 영상 -->
                <div>
                    <div class="mb-16">
                        <more-view-head to="/content/solution">      
                            <v-row align="center" class="row--x-small">
                                <v-col cols="12" md="auto">
                                    <h3 class="tit">영상</h3>
                                </v-col>   
                                <v-col cols="auto" class="d-none d-md-block">
                                    <span class="line mx-2"></span>    
                                </v-col> 
                                <v-col>
                                    <p class="tit tit--sm">전문 리커버들이 말하는 해결책</p>
                                </v-col>   
                            </v-row>
                        </more-view-head>
                    </div>
                    <v-row>
                        <!-- S: 영상 아이템 -->
                        <v-col v-for="solution in solutions" :key="solution._id" cols="6" md="3" @click="$router.push(`/content/solution/${solution._id}`)">
                            <board-item-primary :board="solution"/>
                        </v-col>
                        <!-- E: 영상 아이템 -->
                    </v-row>
                </div>
                <!-- E: 영상 -->

                <!-- S: 칼럼 -->
                <div class="pt-30 pt-lg-60">
                    <div class="mb-16">
                        <more-view-head to="/content/column">      
                            <v-row align="center" class="row--x-small">
                                <v-col cols="12" md="auto">
                                    <h3 class="tit">칼럼</h3>
                                </v-col>   
                                <v-col cols="auto" class="d-none d-md-block">
                                    <span class="line mx-2"></span>    
                                </v-col> 
                                <v-col>
                                    <p class="tit tit--sm">의학 정보, 법률 지식, 일반 상식 등 알차게 채운 중독 뉴스레터</p>
                                </v-col>   
                            </v-row>
                        </more-view-head>
                    </div>
                    <v-row>
                        <!-- S: 칼럼 아이템 -->
                        <v-col v-for="column in columns" :key="column._id" cols="6" md="3" @click="$router.push(`/content/column/${column._id}`)">
                            <board-item-primary :board="column"/>
                        </v-col>
                        <!-- E: 칼럼 아이템 -->
                    </v-row>
                </div>
                <!-- E: 칼럼 -->

                <!-- S: 설문조사 -->
                <div class="pt-30 pt-lg-60">
                    <div class="mb-16">
                        <more-view-head to="/content/survey">      
                            <v-row align="center" class="row--x-small">
                                <v-col cols="12" md="auto">
                                    <h3 class="tit">설문조사</h3>
                                </v-col>   
                                <v-col cols="auto" class="d-none d-md-block">
                                    <span class="line mx-2"></span>    
                                </v-col> 
                                <v-col>
                                    <p class="tit tit--sm">나만의 중독 유형을 알아보는 인터랙티브 설문조사!</p>
                                </v-col>   
                            </v-row>
                        </more-view-head>
                    </div>
                    <v-row>
                        <!-- S: 설문조사 아이템 -->
                        <v-col v-for="survey in surveys" :key="survey._id" cols="6" md="3">
                            <survey-item-primary :survey="survey"/>
                        </v-col>
                        <!-- E: 설문조사 아이템 -->
                    </v-row>
                </div>
                <!-- E: 설문조사 -->

                <!-- S: 테스트 -->
                <div class="pt-30 pt-lg-60">
                    <div class="mb-16">
                        <more-view-head to="/content/test?type=test">      
                            <v-row align="center" class="row--x-small">
                                <v-col cols="12" md="auto">
                                    <h3 class="tit">테스트</h3>
                                </v-col>   
                                <v-col cols="auto" class="d-none d-md-block">
                                    <span class="line mx-2"></span>    
                                </v-col> 
                                <v-col>
                                    <p class="tit tit--sm">나는 지금 중독인가? 중독별 자가 진단!</p>
                                </v-col>   
                            </v-row>
                        </more-view-head>
                    </div>
                    <v-row>
                        <!-- S: 테스트 아이템 -->
                        <v-col v-for="addiction in addictions" :key="addiction._id" cols="6" md="3">
                            <addiction-item-primary :addiction="addiction"/>
                        </v-col>
                        <!-- E: 테스트 아이템 -->
                    </v-row>
                </div>
                <!-- E: 테스트 -->
                
            </v-container>
        </section>
    </client-page>
</template>

<script>
import api from "@/api"
import ClientPage from "@/pages/client/templates/ClientPage.vue";
import ContentHead from "@/pages/client/content/ContentHead.vue";
import MoreViewHead from "@/components/dumb/more-view-head.vue";
import BoardItemPrimary from "@/components/client/board/board-item--primary.vue";
import SurveyItemPrimary from "@/components/client/survey/survey-item--primary.vue";
import AddictionItemPrimary from "@/components/client/addiction/addiction-item--primary.vue";

export default {
    components: {
        ClientPage,
        ContentHead,
        MoreViewHead,
        BoardItemPrimary,
        SurveyItemPrimary,
        AddictionItemPrimary,
    },
    data() {
        return {

            filter: {
                sortKey: null
            },

            solutions: [],
            columns: [],
            surveys: [],
            addictions: [],

            sortKeys: [
                { text: "정렬순", value: null },
                { text: "조회수", value: "viewCount" },
                { text: "추천수", value: "likeCount" },
                { text: "최신순", value: "createdAt" },
            ],
        }
    },
    async mounted() {
        await this.init();
    },
    methods: {
        async init() {
            let [{ boards: solutions }, { boards: columns }, { surveys }, { addictions }] = await Promise.all([
                api.v1.boards.gets({ headers: { limit: 4 }, params: { ...this.filter, code: "content" } }),
                api.v1.boards.gets({ headers: { limit: 4 }, params: { ...this.filter, code: "column" } }),
                api.v1.surveys.gets({ headers: { limit: 4 }, params: { ...this.filter }}),
                api.v1.addictions.test.gets({headers: { limit: 4 }, params: { ...this.filter, type: "test" }})
            ])

            this.solutions = solutions;
            this.columns = columns;
            this.surveys = surveys;
            this.addictions = addictions;
        }
    }
};
</script>

<style lang="scss" scoped>
.line{
    display: block;
    width: 1px;
    height: 30px;
    background-color: var(--border-color);
}
</style>
