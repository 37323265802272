var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('recover-head', {
    attrs: {
      "recover": _vm.recover.recover,
      "contentTotalCount": _vm.contentTotalCount,
      "columnTotalCount": _vm.columnTotalCount,
      "qnaTotalCount": _vm.qnaTotalCount
    }
  }), _c('v-container', [_c('v-row', {
    staticClass: "row--large"
  }, [_c('v-col', {
    attrs: {
      "cols": "12",
      "lg": "6"
    }
  }, [_vm.contentBoard.length > 0 ? _c('recover-solution', {
    attrs: {
      "boards": _vm.contentBoard
    }
  }) : _vm._e()], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "lg": "6"
    }
  }, [_vm.columnBoard.length > 0 ? _c('recover-column', {
    attrs: {
      "boards": _vm.columnBoard
    }
  }) : _vm._e()], 1)], 1)], 1), _c('recover-qna', {
    on: {
      "totalCount": function (totalCount) {
        _vm.qnaTotalCount = totalCount;
      }
    }
  })], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }