<template>
    <client-page>
        <section class="section--first section--last">
            <v-container>
                <board-view />
            </v-container>
        </section>
    </client-page>
</template>

<script>
import api from "@/api";
import ClientPage from "@/pages/client/templates/ClientPage.vue";
import BoardList from "@/components/client/board/skin/content/list.vue";
import BoardView from "@/components/client/board/skin/content/view.vue";

export default {
    components: {
        ClientPage,
        BoardList,
        BoardView,
    },
    async mounted() {
        // if(!this.logon){
        //     alert("로그인 이후에 사용가능합니다");
        //     this.$router.push(`/login`);
        //     return;
        // }
    },
    computed: {
        accessToken(){
            return this.$store.state.accessToken;
        },
        logon(){ 
            return !!this.accessToken 
        },
    }
};
</script>
