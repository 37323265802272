var render = function render(){
  var _vm$recover, _vm$recover$thumb, _vm$recover2, _vm$recover3;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('section', {
    staticClass: "recover-head"
  }, [_c('v-container', [_c('v-row', {
    attrs: {
      "no-gutters": ""
    }
  }, [_c('v-col', {
    staticClass: "recover-head__left",
    attrs: {
      "cols": "12",
      "lg": "6"
    }
  }, [_c('v-img', {
    staticClass: "recover-head__left__logo",
    attrs: {
      "src": "/images/ci.svg",
      "aspect-ratio": 380 / 60,
      "contain": ""
    }
  }), _c('div', {
    staticClass: "recover-head__left__contents py-60 pr-md-10 pr-xl-60 h-100"
  }, [_c('v-row', {
    staticClass: "h-100",
    attrs: {
      "align": "end"
    }
  }, [_c('v-col', {
    staticClass: "mb-20 mb-md-0",
    attrs: {
      "cols": "12",
      "md": "auto"
    }
  }, [_c('v-card', {
    attrs: {
      "rounded": "",
      "width": _vm.$vuetify.breakpoint.mdAndUp ? 250 : '100%'
    }
  }, [_c('v-img', {
    staticClass: "w-100",
    attrs: {
      "src": `${(_vm$recover = _vm.recover) === null || _vm$recover === void 0 ? void 0 : (_vm$recover$thumb = _vm$recover.thumb) === null || _vm$recover$thumb === void 0 ? void 0 : _vm$recover$thumb.src}`,
      "aspect-ratio": 1 / 1
    }
  })], 1)], 1), _c('v-col', [_c('v-row', {
    staticClass: "row--x-small"
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-chip', {
    attrs: {
      "large": "",
      "color": "primary-50",
      "text-color": "primary"
    }
  }, [_vm._v(_vm._s(_vm.setAddiction(_vm.recover.addiction)[0].name))])], 1)], 1), _c('div', {
    staticClass: "mt-8 mt-lg-16 mb-16 mb-lg-26"
  }, [_c('v-row', {
    staticClass: "row--x-small"
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('h3', {
    staticClass: "tit line-height-1"
  }, [_vm._v(_vm._s((_vm$recover2 = _vm.recover) === null || _vm$recover2 === void 0 ? void 0 : _vm$recover2.name))])]), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-divider', {
    attrs: {
      "vertical": ""
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('h4', {
    staticClass: "tit tit--sm font-weight-regular mt-2"
  }, [_vm._v(_vm._s((_vm$recover3 = _vm.recover) === null || _vm$recover3 === void 0 ? void 0 : _vm$recover3.job))])])], 1)], 1), _c('v-row', {
    staticClass: "ma-lg-n6 white--text text-center"
  }, [_c('v-col', {
    staticClass: "pa-lg-6",
    attrs: {
      "cols": "4"
    }
  }, [_c('v-card', {
    staticClass: "recover-link recover-link--1",
    attrs: {
      "to": "#solution",
      "tile": "",
      "dark": "",
      "color": "primary",
      "height": _vm.$vuetify.breakpoint.mdAndUp ? 108 : 65
    }
  }, [_c('div', {
    staticClass: "pa-10 py-md-24"
  }, [_c('p', {
    staticClass: "page-text font-weight-medium"
  }, [_vm._v("영상")]), _vm.contentTotalCount !== 0 ? _c('h3', {
    staticClass: "countup tit line-height-1 mt-6 mt-lg-10"
  }, [_vm._v(_vm._s(_vm.contentTotalCount))]) : _vm._e()])])], 1), _c('v-col', {
    staticClass: "pa-lg-6",
    attrs: {
      "cols": "4"
    }
  }, [_c('v-card', {
    staticClass: "recover-link recover-link--2",
    attrs: {
      "to": "#column",
      "tile": "",
      "dark": "",
      "color": "primary",
      "height": _vm.$vuetify.breakpoint.mdAndUp ? 108 : 65
    }
  }, [_c('div', {
    staticClass: "pa-10 py-md-24"
  }, [_c('p', {
    staticClass: "page-text font-weight-medium"
  }, [_vm._v("칼럼")]), _vm.columnTotalCount !== 0 ? _c('h3', {
    staticClass: "countup tit line-height-1 mt-6 mt-lg-10"
  }, [_vm._v(_vm._s(_vm.columnTotalCount))]) : _vm._e()])])], 1), _c('v-col', {
    staticClass: "pa-lg-6",
    attrs: {
      "cols": "4"
    }
  }, [_c('v-card', {
    staticClass: "recover-link recover-link--3",
    attrs: {
      "to": "#qna",
      "tile": "",
      "dark": "",
      "color": "primary",
      "height": _vm.$vuetify.breakpoint.mdAndUp ? 108 : 65
    }
  }, [_c('div', {
    staticClass: "pa-10 py-md-24"
  }, [_c('p', {
    staticClass: "page-text font-weight-medium"
  }, [_vm._v("QnA")]), _vm.qnaTotalCount !== 0 ? _c('h3', {
    staticClass: "countup tit line-height-1 mt-6 mt-lg-10"
  }, [_vm._v(_vm._s(_vm.qnaTotalCount))]) : _vm._e()])])], 1)], 1)], 1)], 1)], 1)], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "lg": "6"
    }
  }, [_c('div', {
    staticClass: "py-60 pl-md-10 pl-xl-60"
  }, [_c('h3', {
    staticClass: "tit mb-12 mb-lg-24"
  }, [_vm._v("리커버 소개")]), _c('div', {
    staticClass: "scroll-area"
  }, [_c('p', {
    staticClass: "page-text grey-6--text",
    domProps: {
      "innerHTML": _vm._s(_vm.recover.introduction)
    }
  })])])])], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }