<template>
    <section class="recover-head">
        <v-container>
            <v-row no-gutters>
                <v-col cols="12" lg="6" class="recover-head__left">
                    <v-img src="/images/ci.svg" :aspect-ratio="380 / 60" contain class="recover-head__left__logo"/>
                    <div class="recover-head__left__contents py-60 pr-md-10 pr-xl-60 h-100">
                        <v-row align="end" class="h-100">
                            <v-col cols="12" md="auto" class="mb-20 mb-md-0">
                                <v-card rounded :width="$vuetify.breakpoint.mdAndUp ? 250 : '100%'">
                                    <!-- 썸네일 -->
                                    <v-img :src="`${recover?.thumb?.src}`" :aspect-ratio="1 / 1" class="w-100" />
                                </v-card>
                            </v-col>
                            <v-col>
                                <v-row class="row--x-small">
                                    <!-- S: 카테고리 -->
                                    <v-col cols="auto">
                                        <v-chip large color="primary-50" text-color="primary">{{ setAddiction(recover.addiction)[0].name }}</v-chip>
                                    </v-col>
                                    <!-- E: 카테고리 -->
                                </v-row>
                                <div class="mt-8 mt-lg-16 mb-16 mb-lg-26">
                                    <v-row class="row--x-small">
                                        <v-col cols="auto">
                                            <!-- 리커버 -->
                                            <h3 class="tit line-height-1">{{ recover?.name }}</h3>
                                        </v-col>
                                        <v-col cols="auto">
                                            <v-divider vertical />
                                        </v-col>
                                        <v-col cols="auto">
                                            <!-- 분야 -->
                                            <h4 class="tit tit--sm font-weight-regular mt-2">{{ recover?.job }}</h4>
                                        </v-col>
                                    </v-row>
                                </div>
                                <v-row class="ma-lg-n6 white--text text-center">
                                    <v-col cols="4" class="pa-lg-6">
                                        <v-card to="#solution" tile dark color="primary" :height="$vuetify.breakpoint.mdAndUp ? 108 : 65" class="recover-link recover-link--1">
                                            <div class="pa-10 py-md-24">
                                                <p class="page-text font-weight-medium">영상</p>
                                                <!-- 영상 -->
                                                <h3 v-if="contentTotalCount !== 0" class="countup tit line-height-1 mt-6 mt-lg-10">{{ contentTotalCount }}</h3>
                                            </div>
                                        </v-card>
                                    </v-col>
                                    <v-col cols="4" class="pa-lg-6">
                                        <v-card to="#column" tile dark color="primary" :height="$vuetify.breakpoint.mdAndUp ? 108 : 65" class="recover-link recover-link--2">
                                            <div class="pa-10 py-md-24">
                                                <p class="page-text font-weight-medium">칼럼</p>
                                                <!-- 칼럼 -->
                                                <h3 v-if="columnTotalCount !== 0" class="countup tit line-height-1 mt-6 mt-lg-10">{{ columnTotalCount }}</h3>
                                            </div>
                                        </v-card>
                                    </v-col>
                                    <v-col cols="4" class="pa-lg-6">
                                        <v-card to="#qna" tile dark color="primary" :height="$vuetify.breakpoint.mdAndUp ? 108 : 65" class="recover-link recover-link--3">
                                            <div class="pa-10 py-md-24">
                                                <p class="page-text font-weight-medium">QnA</p>
                                                <!-- QnA -->
                                                <h3 v-if="qnaTotalCount !== 0" class="countup tit line-height-1 mt-6 mt-lg-10">{{ qnaTotalCount }}</h3>
                                            </div>
                                        </v-card>
                                    </v-col>
                                </v-row>
                            </v-col>
                        </v-row>
                    </div>
                </v-col>
                <v-col cols="12" lg="6">
                    <div class="py-60 pl-md-10 pl-xl-60">
                        <h3 class="tit mb-12 mb-lg-24">리커버 소개</h3>
                        <div class="scroll-area">

                            <!-- 리커버 소개 상세 -->
                            <p v-html="recover.introduction" class="page-text grey-6--text"></p>

                            <!-- <div class="mb-n12 mb-lg-n24">
                                S: 소개
                                <div class="mb-12 mb-lg-24">
                                    <v-row class="ma-lg-n12">
                                        <v-col cols="auto" class="pa-lg-12">
                                            소개 제목
                                            <p class="page-text font-weight-medium">리커버 소개</p>
                                        </v-col>
                                        <v-col cols="auto">
                                            <v-divider vertical class="primary" />
                                        </v-col>
                                        <v-col>
                                            <div class="mb-n2">
                                                S: 내용
                                                <p class="page-text grey-6--text mb-2">한국 역술인협회 학술위원</p>
                                                E: 내용
                                            </div>
                                        </v-col>
                                    </v-row>
                                </div>
                                E: 소개
                            </div> -->
                        </div>
                    </div>
                </v-col>
            </v-row>
        </v-container>
    </section>
</template>

<script>
import api from "@/api"
export default {
    props: {
        recover: { type: Object, default: () => {} },
        contentTotalCount: { type: Number },
        columnTotalCount: { type: Number },
        qnaTotalCount: { type: Number }
    },
    data() {
        return {
            addictions: []
        }
    },
    mounted() {
        this.init();
    },
    methods: {
        async init() {
            let { addictions } = await api.v1.users.addiction.gets()
            this.addictions = addictions;
            setTimeout(function() {
                // countup
                const animationDuration = 2000;
                const frameDuration = 1000 / 60;
                const totalFrames = Math.round(animationDuration / frameDuration);
                const easeOutQuad = (t) => t * (2 - t);

                const animateCountUp = (el) => {
                    let frame = 0;
                    const countTo = parseInt(el.innerHTML, 10);
                    const counter = setInterval(() => {
                        frame++;
                        const progress = easeOutQuad(frame / totalFrames);
                        const currentCount = Math.round(countTo * progress);

                        if (parseInt(el.innerHTML, 10) !== currentCount) {
                            el.innerHTML = currentCount;
                        }

                        if (frame === totalFrames) {
                            clearInterval(counter);
                        }
                    }, frameDuration);
                };

                const countupEls = document.querySelectorAll(".countup");
                countupEls.forEach(animateCountUp);
            }, 10);
        },
        setAddiction(code) {
            return this.addictions.filter(el => {
                return el.code === code
            })
        }
    },

}
</script>

<style lang="scss" scoped>
::v-deep{
    .v-chip.v-size--large {
        border-radius: 14px;
        font-size: 1.4rem;
        height: 28px;
        min-width: 0;
        padding: 0 16px;
        &::before{
            display: none;
        }
    }
}
.recover-link{
    &--1{
        border-top-left-radius: 12px !important;
        border-bottom-left-radius: 12px !important;
    }
    &--3{
        border-top-right-radius: 12px !important;
        border-bottom-right-radius: 12px !important;
    }
}
.recover-head{
    &__left{
        position: relative;
        &::before{
            content: "";
            display: block;
            position: absolute;
            width: 100vw;
            height: 100%;
            left: 50%;
            top: 0;
            transform: translateX(-50%);
            background-color: #fafafa;
            z-index: 0;
        }
        &__logo{
            position: absolute;
            width: 240px;
            bottom: 0;
            left: 0;
            opacity: .1;
        }
        &__contents{
            position: relative;
            z-index: 1;
        }
    }
}
.scroll-area{
    max-height: 300px;
    overflow-x: hidden;
    overflow-y: auto;
    &::-webkit-scrollbar{width: 4px;}
    &::-webkit-scrollbar-thumb{width: 4px; background-color: #aaa; border-radius: 2px}
    &::-webkit-scrollbar-track{width: 4px; background-color: #ececec; border-radius: 2px;}
}
@media (min-width:576px){
}
@media (min-width:768px){
}
@media (min-width:1024px){
    ::v-deep{
        .v-chip.v-size--large {
            border-radius: 14px;
            font-size: 1.4rem;
            height: 28px;
            min-width: 0;
            padding: 0 16px;
        }
    }
    .recover-head{
        &__left{
            position: relative;
            &::before{
                left: unset;
                right: 0;
                transform: translateX(0);
            }
            &__logo{
                width: 380px;
            }
        }
    }
    .recover-link{
        &--1{
            border-top-left-radius: 24px !important;
            border-bottom-left-radius: 24px !important;
        }
        &--3{
            border-top-right-radius: 24px !important;
            border-bottom-right-radius: 24px !important;
        }
    }
}
@media (min-width:1200px){
}

</style>