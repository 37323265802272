var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('section', {
    staticClass: "section section--first section--column pb-0",
    attrs: {
      "id": "column"
    }
  }, [_c('div', {
    staticClass: "mb-16"
  }, [_c('more-view-head', {
    attrs: {
      "to": "/content/column"
    }
  }, [_c('v-row', {
    staticClass: "row--small flex-nowrap",
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('h3', {
    staticClass: "tit"
  }, [_vm._v("칼럼")])]), _c('v-col', [_c('v-row', {
    staticClass: "row--x-small banner-slide__control flex-nowrap"
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-btn', {
    staticClass: "slide-btn slide-btn--prev",
    attrs: {
      "icon": "",
      "x-small": ""
    }
  }, [_c('i', {
    staticClass: "icon icon-left-arrow icon-left-arrow--sm"
  })])], 1), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-btn', {
    staticClass: "slide-btn slide-btn--next",
    attrs: {
      "icon": "",
      "x-small": ""
    }
  }, [_c('i', {
    staticClass: "icon icon-right-arrow icon-right-arrow--sm"
  })])], 1)], 1)], 1)], 1)], 1)], 1), _c('div', {
    staticClass: "column-slide"
  }, [_c('div', {
    staticClass: "swiper-container"
  }, [_c('div', {
    staticClass: "swiper-wrapper"
  }, _vm._l(_vm.boards, function (board) {
    return _c('div', {
      key: board._id,
      staticClass: "swiper-slide",
      on: {
        "click": function ($event) {
          return _vm.move(board._id);
        }
      }
    }, [_c('board-item-primary', {
      attrs: {
        "board": board
      }
    })], 1);
  }), 0)])])]);

}
var staticRenderFns = []

export { render, staticRenderFns }