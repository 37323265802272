var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('client-page', [_c('section', {
    staticClass: "section--first section--last"
  }, [_c('v-container', [_c('content-head'), _c('div', {
    staticClass: "mb-20 mb-lg-30"
  }, [_c('v-row', {
    staticClass: "row--x-small"
  }, [_c('v-spacer'), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-select', {
    staticClass: "v-input--small w-100px",
    attrs: {
      "items": _vm.sortKeys,
      "outlined": "",
      "hide-details": ""
    },
    on: {
      "change": function ($event) {
        return _vm.init();
      }
    },
    model: {
      value: _vm.filter.sortKey,
      callback: function ($$v) {
        _vm.$set(_vm.filter, "sortKey", $$v);
      },
      expression: "filter.sortKey"
    }
  })], 1)], 1)], 1), _c('div', [_c('div', {
    staticClass: "mb-16"
  }, [_c('more-view-head', {
    attrs: {
      "to": "/content/solution"
    }
  }, [_c('v-row', {
    staticClass: "row--x-small",
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "12",
      "md": "auto"
    }
  }, [_c('h3', {
    staticClass: "tit"
  }, [_vm._v("영상")])]), _c('v-col', {
    staticClass: "d-none d-md-block",
    attrs: {
      "cols": "auto"
    }
  }, [_c('span', {
    staticClass: "line mx-2"
  })]), _c('v-col', [_c('p', {
    staticClass: "tit tit--sm"
  }, [_vm._v("전문 리커버들이 말하는 해결책")])])], 1)], 1)], 1), _c('v-row', _vm._l(_vm.solutions, function (solution) {
    return _c('v-col', {
      key: solution._id,
      attrs: {
        "cols": "6",
        "md": "3"
      },
      on: {
        "click": function ($event) {
          return _vm.$router.push(`/content/solution/${solution._id}`);
        }
      }
    }, [_c('board-item-primary', {
      attrs: {
        "board": solution
      }
    })], 1);
  }), 1)], 1), _c('div', {
    staticClass: "pt-30 pt-lg-60"
  }, [_c('div', {
    staticClass: "mb-16"
  }, [_c('more-view-head', {
    attrs: {
      "to": "/content/column"
    }
  }, [_c('v-row', {
    staticClass: "row--x-small",
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "12",
      "md": "auto"
    }
  }, [_c('h3', {
    staticClass: "tit"
  }, [_vm._v("칼럼")])]), _c('v-col', {
    staticClass: "d-none d-md-block",
    attrs: {
      "cols": "auto"
    }
  }, [_c('span', {
    staticClass: "line mx-2"
  })]), _c('v-col', [_c('p', {
    staticClass: "tit tit--sm"
  }, [_vm._v("의학 정보, 법률 지식, 일반 상식 등 알차게 채운 중독 뉴스레터")])])], 1)], 1)], 1), _c('v-row', _vm._l(_vm.columns, function (column) {
    return _c('v-col', {
      key: column._id,
      attrs: {
        "cols": "6",
        "md": "3"
      },
      on: {
        "click": function ($event) {
          return _vm.$router.push(`/content/column/${column._id}`);
        }
      }
    }, [_c('board-item-primary', {
      attrs: {
        "board": column
      }
    })], 1);
  }), 1)], 1), _c('div', {
    staticClass: "pt-30 pt-lg-60"
  }, [_c('div', {
    staticClass: "mb-16"
  }, [_c('more-view-head', {
    attrs: {
      "to": "/content/survey"
    }
  }, [_c('v-row', {
    staticClass: "row--x-small",
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "12",
      "md": "auto"
    }
  }, [_c('h3', {
    staticClass: "tit"
  }, [_vm._v("설문조사")])]), _c('v-col', {
    staticClass: "d-none d-md-block",
    attrs: {
      "cols": "auto"
    }
  }, [_c('span', {
    staticClass: "line mx-2"
  })]), _c('v-col', [_c('p', {
    staticClass: "tit tit--sm"
  }, [_vm._v("나만의 중독 유형을 알아보는 인터랙티브 설문조사!")])])], 1)], 1)], 1), _c('v-row', _vm._l(_vm.surveys, function (survey) {
    return _c('v-col', {
      key: survey._id,
      attrs: {
        "cols": "6",
        "md": "3"
      }
    }, [_c('survey-item-primary', {
      attrs: {
        "survey": survey
      }
    })], 1);
  }), 1)], 1), _c('div', {
    staticClass: "pt-30 pt-lg-60"
  }, [_c('div', {
    staticClass: "mb-16"
  }, [_c('more-view-head', {
    attrs: {
      "to": "/content/test?type=test"
    }
  }, [_c('v-row', {
    staticClass: "row--x-small",
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "12",
      "md": "auto"
    }
  }, [_c('h3', {
    staticClass: "tit"
  }, [_vm._v("테스트")])]), _c('v-col', {
    staticClass: "d-none d-md-block",
    attrs: {
      "cols": "auto"
    }
  }, [_c('span', {
    staticClass: "line mx-2"
  })]), _c('v-col', [_c('p', {
    staticClass: "tit tit--sm"
  }, [_vm._v("나는 지금 중독인가? 중독별 자가 진단!")])])], 1)], 1)], 1), _c('v-row', _vm._l(_vm.addictions, function (addiction) {
    return _c('v-col', {
      key: addiction._id,
      attrs: {
        "cols": "6",
        "md": "3"
      }
    }, [_c('addiction-item-primary', {
      attrs: {
        "addiction": addiction
      }
    })], 1);
  }), 1)], 1)], 1)], 1)]);

}
var staticRenderFns = []

export { render, staticRenderFns }