<template>
    <div>
        <recover-head :recover="recover.recover" :contentTotalCount="contentTotalCount" :columnTotalCount="columnTotalCount" :qnaTotalCount="qnaTotalCount"/>
        <v-container>
            <v-row class="row--large">
                <v-col cols="12" lg="6">
                    <recover-solution v-if="contentBoard.length > 0" :boards="contentBoard"/>
                </v-col>
                <v-col cols="12" lg="6">
                    <recover-column v-if="columnBoard.length > 0" :boards="columnBoard"/>
                </v-col>
            </v-row>
        </v-container>
        <recover-qna @totalCount="(totalCount) => {qnaTotalCount = totalCount}"/>
    </div>
</template>

<script>
import api from "@/api"
import RecoverHead from "@/components/client/recover/recover-head.vue";
import RecoverSolution from "@/components/client/recover/recover-solution.vue";
import RecoverColumn from "@/components/client/recover/recover-column.vue";
import RecoverQna from "@/components/client/recover/recover-qna.vue";

export default {
    components: {
        RecoverHead,
        RecoverSolution,
        RecoverColumn,
        RecoverQna,
    },
    async mounted() {
        await this.init();
    },
    data() {
        return {
            recover: null,
            contentTotalCount: 0,
            columnTotalCount: 0,
            qnaTotalCount: 0,
            contentBoard: [],
            columnBoard: []
        }
    },
    methods: {
        async init() {
            let { recover } = await api.v1.users.recover({ _id: this.$route.params._recover })
            this.recover = recover
            let { summary: contentSummary, boards: contentBoard } = await api.v1.boards.gets({
                headers: {
                    limit: 4
                },
                params: {
                    code: "content",
                    _recover: this.recover._id
                }
            })
            
            let { summary: columnSummary, boards: columnBoard } = await api.v1.boards.gets({
                headers: {
                    limit: 4
                },
                params: {
                    code: "column",
                    _recover: this.recover._id
                }
            })
            this.contentBoard = contentBoard;
            this.contentTotalCount = contentSummary.totalCount
            this.columnBoard = columnBoard;
            this.columnTotalCount = columnSummary.totalCount
        }
    }
}
</script>

<style lang="scss" scoped>
</style>