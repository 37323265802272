<template>
    <client-page class="app--main">
        <section class="section section--last">
            <v-container>
                <div class="tit-wrap text-center">
                    <h2 class="tit tit--lg">
                        <span :style="`color: ${addiction.themeColor.hexa}`">{{ addiction.subject }}</span>
                        테스트
                    </h2>
                </div>
                <v-tabs-items v-model="tab" touchless>
                    <v-tab-item v-for="(question, index) in addiction.questions" :key="index">
                        <addiction-question-view :themeColor="addiction.themeColor.hexa" :questions="addiction.questions" :question="question" :index="index + 1" @next="(answer) => answerNext(answer)" />
                    </v-tab-item>
                </v-tabs-items>
                <div class="mt-20 mt-lg-40">
                    <v-row class="row--small" justify="center">
                        <v-col cols="auto" v-if="tab !== 0">
                            <v-btn icon color="grey-6" @click="prev">
                                <i class="icon icon-left-arrow"></i>
                            </v-btn>
                        </v-col>
                        <v-col cols="auto" v-if="tab !== addiction.questions.length - 1">
                            <v-btn icon color="grey-6" @click="next">
                                <i class="icon icon-right-arrow"></i>
                            </v-btn>
                        </v-col>
                    </v-row>
                </div>
            </v-container>
        </section>
        <!-- <div class="main-contents">
            <v-container>
                <v-col>
                    <div>
                        <span :style="`color: ${addiction.themeColor.hexa}`">{{ addiction.subject }}</span>
                        테스트
                    </div>
                    <v-tabs-items v-model="tab" touchless>
                        <v-tab-item v-for="(question, index) in addiction.questions" :key="index">
                            <addiction-question-view :questions="addiction.questions" :question="question" :index="index + 1" @next="(answer) => answerNext(answer)" />
                        </v-tab-item>
                    </v-tabs-items>
                    <div>
                        <v-btn v-if="tab !== 0" color="primary" @click="prev">이전</v-btn>
                        <v-btn v-if="tab !== addiction.questions.length - 1" color="primary" @click="next">다음</v-btn>
                    </div>
                </v-col>
            </v-container>
        </div> -->
    </client-page>
</template>

<script>
import api from "@/api";
import ClientPage from "@/pages/client/templates/ClientPage.vue";
import AddictionQuestionView from "@/components/client/addiction/addiction-questions-view.vue";

export default {
    components: {
        ClientPage,
        AddictionQuestionView,
    },

    data() {
        return {
            page: 1,
            limit: 6,
            pageCount: 0,

            tab: 0,
            addiction: null,
            addictionResult: {
                selectAnswers: [],

                result: {
                    score: null,
                    status: null,
                    need1: null,
                    need2: null,
                    content: null,
                },
            },

            type: "number",
            number: 0,
            duration: 0,
            offset: 0,
            easing: "easeInOutCubic",

            show: false,
        };
    },

    async mounted() {
        await this.init();
    },

    methods: {
        async init() {
            let { addiction } = await api.v1.addictions.checkup.get();
            this.addiction = addiction;
        },

        async save() {
            let { result } = await api.v1.addictions.result.post({ ...this.addictionResult, _addiction: this.addiction._id });
            this.$router.push(`/content/test/result/${result._id}`);
        },

        async next() {
            if (this.tab === this.addictionResult.selectAnswers.length) return alert("선택지를 선택해주세요");
            if (this.tab !== this.addiction.questions.length - 1) {
                this.tab++;
                this.scrollTop();
            }
        },

        prev() {
            if (this.tab !== 0) {
                this.addictionResult.selectAnswers.pop();
                this.tab--;
                this.scrollTop();
            }
        },

        async answerNext(answer) {
            this.addictionResult.selectAnswers.push(answer);
            if (this.tab === this.addiction.questions.length - 1) {
                await this.save();
            } else {
                this.tab++;
                this.scrollTop();
            }
        },

        scrollTop() {
            this.$vuetify.goTo(this.target, this.options);
        },

        setColor(themeColor) {
            return themeColor.hexa;
        },
    },
    computed: {
        target() {
            const value = this[this.type];
            if (!isNaN(value)) return Number(value);
            else return value;
        },
        options() {
            return {
                duration: this.duration,
                offset: this.offset,
                easing: this.easing,
            };
        },
    },
};
</script>
