<template>
    <client-page>

        <section class="section--first section--last">
            <v-container>

                <h2 class="tit tit--lg line-height-1 text-center mb-20 mb-lg-40">
                    이용약관
                </h2>
                
                <div v-html="terms.content">
                </div>

            </v-container>
        </section>

    </client-page>
</template>

<script>
import api from '@/api'
import ClientPage from "@/pages/client/templates/ClientPage.vue";

export default {
    components: {
        ClientPage,
    },

    async mounted() {
        await this.init()
    },
    data() {
        return {
            terms: null
        };
    },
    methods: {
        async init() {
            let { terms } = await api.v1.terms.get({code: "terms"})
            this.terms = terms
        }
    }
};
</script>
