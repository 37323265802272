<template>
    <section class="section section--first section--solution pb-0" id="solution">
        <div class="mb-16">
            <more-view-head to="/content/solution">      
                <v-row align="center" class="row--small flex-nowrap">
                    <v-col cols="auto">
                        <h3 class="tit">영상</h3>
                    </v-col>    
                    <v-col>
                        <v-row class="row--x-small banner-slide__control flex-nowrap">
                            <v-col cols="auto">
                                <v-btn icon x-small class="slide-btn slide-btn--prev"><i class="icon icon-left-arrow icon-left-arrow--sm"></i></v-btn>
                            </v-col>
                            <v-col cols="auto">
                                <v-btn icon x-small class="slide-btn slide-btn--next"><i class="icon icon-right-arrow icon-right-arrow--sm"></i></v-btn>
                            </v-col>
                        </v-row>
                    </v-col>   
                </v-row>
            </more-view-head>
        </div>
        <div class="solution-slide">
            <div class="swiper-container">
                <div class="swiper-wrapper">
                    <!-- S: 영상 -->
                    <div v-for="board in boards" :key="board._id" class="swiper-slide" @click="move(board._id)">
                        <board-item-primary :board="board"/>
                    </div>
                    <!-- E: 영상 -->
                </div>
            </div>
        </div>
    </section>    
</template>

<script>
import Swiper from "swiper/bundle";
import MoreViewHead from "@/components/dumb/more-view-head.vue";
import BoardItemPrimary from "@/components/client/board/board-item--primary.vue";
export default {
    props: {
        boards: { type: Array, default: () => [] }
    },
    components: {
        MoreViewHead,
        BoardItemPrimary,
    },
    mounted() {
        this.init();
    },
    methods: {
        init() {
            if(this.boards.length > 0) {
                this.$nextTick(() => {
                    this.swiper = new Swiper(".solution-slide .swiper-container", {
                        spaceBetween: 10,
                        slidesPerView: 2,
                        speed: 500,
                        loop: true,
                        lazy: {
                        loadPrevNext: true,
                        loadPrevNextAmount: 4
                        },
                        loopedSlides: 4,
                        autoplay: {
                            delay: 10000,
                            disableOnInteraction: false,
                        },
                        navigation: {
                            nextEl: ".section--solution .slide-btn--next",
                            prevEl: ".section--solution .slide-btn--prev",
                        },
                        breakpoints: {
                            768:{
                                slidesPerView: 3,
                                spaceBetween: 10,
                            },
                            1024:{
                                slidesPerView: 2,
                                spaceBetween: 20
                                ,
                            },
                        },
                    });
                });
            }
        },
        move(_id) {
            this.$router.push(`/content/solution/${_id}`)
        }
    }
}
</script>

<style lang="scss" scoped>
.slide-btn{
    &.theme--light.v-btn:not(.v-btn--flat):not(.v-btn--text):not(.v-btn--outlined) {
        background-color: rgba(0, 0, 0, .3);
    }
}
</style>