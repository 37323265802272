var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('client-page', [_c('section', {
    staticClass: "section--first section--last"
  }, [_c('v-container', [_c('content-head'), _c('board-list', {
    attrs: {
      "code": _vm.code
    },
    model: {
      value: _vm.category,
      callback: function ($$v) {
        _vm.category = $$v;
      },
      expression: "category"
    }
  })], 1)], 1)]);

}
var staticRenderFns = []

export { render, staticRenderFns }