var render = function render(){
  var _vm$result, _vm$result2, _vm$result2$survey, _vm$result3, _vm$result3$survey, _vm$result4;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('client-page', {
    staticClass: "app--main"
  }, [_c('section', {
    staticClass: "section section--last"
  }, [_vm.result ? _c('v-container', [!((_vm$result = _vm.result) !== null && _vm$result !== void 0 && _vm$result.content) ? _c('div', {
    staticClass: "d-flex flex-column align-center text-center"
  }, [_c('h2', {
    staticClass: "tit tit--lg line-height-1 primary--text"
  }, [_vm._v(_vm._s((_vm$result2 = _vm.result) === null || _vm$result2 === void 0 ? void 0 : (_vm$result2$survey = _vm$result2.survey) === null || _vm$result2$survey === void 0 ? void 0 : _vm$result2$survey.subject) + " 설문조사")]), _c('i', {
    staticClass: "icon icon-check icon-check--fill mt-20 mt-lg-44"
  }), _c('h3', {
    staticClass: "tit grey-6--text mt-20 mt-lg-40"
  }, [_vm._v("설문에 참여해 주셔서 감사합니다.")]), _c('v-btn', {
    staticClass: "mt-20 mt-lg-40",
    attrs: {
      "to": "/content/survey",
      "exact": "",
      "large": "",
      "color": "primary"
    }
  }, [_vm._v("다른 설문조사 하러가기")])], 1) : _c('div', {
    staticClass: "d-flex flex-column align-center text-center"
  }, [_c('h2', {
    staticClass: "tit tit--lg line-height-1 primary--text"
  }, [_vm._v(_vm._s((_vm$result3 = _vm.result) === null || _vm$result3 === void 0 ? void 0 : (_vm$result3$survey = _vm$result3.survey) === null || _vm$result3$survey === void 0 ? void 0 : _vm$result3$survey.subject) + " 설문조사")]), _c('p', {
    staticClass: "w-100 mt-40 mt-lg-80",
    attrs: {
      "max-width": "520",
      "aspect-ratio": 520 / 668
    },
    domProps: {
      "innerHTML": _vm._s((_vm$result4 = _vm.result) === null || _vm$result4 === void 0 ? void 0 : _vm$result4.content)
    }
  }), _c('v-btn', {
    staticClass: "mt-40 mt-lg-80",
    attrs: {
      "to": "/content/survey",
      "exact": "",
      "large": "",
      "color": "primary"
    }
  }, [_vm._v("다른 설문조사 하러가기")])], 1)]) : _vm._e()], 1)]);

}
var staticRenderFns = []

export { render, staticRenderFns }