<template>
    <client-page class="app--main">
        <section class="section section--first section--last">
            <v-container>

                <content-head />

                <div class="mb-20 mb-lg-30">
                    <v-row align="center" class="row--x-small">
                        <v-spacer />
                        <v-col cols="auto">
                            <addiction-filter :categories="categories" @apply="setFilter"/>
                        </v-col>
                    </v-row>
                </div>

                <div>
                    <div class="mb-16">
                        <v-row no-gutters align="center">
                            <h3 class="tit">인기 테스트</h3>
                        </v-row>
                    </div>
                    <div class="mb-n12 mb-sm-0">
                        <v-row class="my-n10 my-lg-n30">
                            <v-col v-for="addiction in popularAddictions" :key="addiction._id" cols="12" sm="3" class="py-10 py-lg-30">
                                <router-link :to="`/content/test/${addiction._id}`">
                                    <addiction-item-primary :addiction="addiction"></addiction-item-primary>
                                </router-link>
                            </v-col>
                        </v-row>
                    </div>
                </div>

                <div class="mt-40 mt-lg-80">
                    <div class="mb-16">
                        <v-row no-gutters align="center">
                            <h3 class="tit">최신 테스트</h3>
                        </v-row>
                    </div>
                    <div class="mb-n12 mb-sm-0">
                        <v-row class="my-n10 my-lg-n30">
                            <v-col v-for="addiction in addictions" :key="addiction._id" cols="12" sm="3" class="py-10 py-lg-30">
                                <addiction-item-primary :addiction="addiction"></addiction-item-primary>
                            </v-col>
                        </v-row>
                    </div>
                </div>

                <v-divider class="mt-30 mt-lg-60 mb-20 mb-lg-40"/>

                <v-row class="row--x-small search--primary">
                    <v-col cols="12" md="auto">
                        <v-select v-model="filter.searchKey" :items="searchKeys" class="v-input--small w-100 w-md-120px" outlined hide-details @input="search(true)"></v-select>
                    </v-col>
                    <v-col cols="9" md="auto">
                        <v-text-field v-model="filter.searchValue" class="v-input--small w-100 w-md-240px" outlined hide-details placeholder="검색어를 입력하세요." :disabled="!filter.searchKey" @input="search(true)"/>
                    </v-col>
                    <v-col cols="3" md="auto">
                        <v-btn class="h-100 w-100 min-w-md-60px" color="grey-6" @click="search(false)">
                            <span class="white--text font-size-14">검색</span>
                        </v-btn>
                    </v-col>
                </v-row>

                <div class="v-pagination-wrap">
                    <v-pagination v-model="page" :length="pageCount" @input="search(fasle)"></v-pagination>
                </div>

            </v-container>
        </section>
    </client-page>
</template>

<script>
import api from "@/api";
import ClientPage from "@/pages/client/templates/ClientPage.vue";
import ContentHead from "@/pages/client/content/ContentHead.vue";
import AddictionFilter from "@/components/client/addiction/addiction-filter.vue";
import AddictionItemPrimary from "@/components/client/addiction/addiction-item--primary.vue";
import SearchPrimary from "@/components/client/board/search--primary.vue";

export default {
    components: {
        ClientPage,
        ContentHead,
        AddictionFilter,
        AddictionItemPrimary,
        SearchPrimary,
    },

    data() {
        return {
            page: 1,
            limit: 8,
            pageCount: 0,

            popularAddictions: [],

            filter: {
                code: null,
                sortKey: null,
                searchKey: this.$route.query.searchKey || "subjectcontent",
                searchValue: this.$route.query.searchValue || null,
            },
            searchKeys: [
                { text: "제목 + 내용", value: "subjectcontent" },
                { text: "제목", value: "subject" },
            ],
            addictions: [],
            summary: { totalCount: 0 },
            categories: null
        };
    },

    async mounted() {
        if(!this.logon){
            alert("로그인 이후에 사용가능합니다");
            this.$router.push(`/login`);
            return;
        }
        await this.init();
    },

    methods: {
        async init() {
            let { categories } = await api.v1.addictions.categories.gets({ params: { depth: 1 } })
            this.categories = categories;
            this.search(false);
        },

        async search(routable = false) {
            if (routable) {
                this.$router.push({
                    query: { ...this.filter, page: this.page },
                });
                return;
            }
            try {
                let { addictions: popularAddictions } = await api.v1.addictions.test.gets({ params: { isPopular: true } });

                this.popularAddictions = popularAddictions;

                let { summary, addictions } = await api.v1.addictions.test.gets({
                    headers: {
                        limit: this.limit,
                        skip: (this.page - 1) * this.limit,
                    },
                    params: {
                        ...this.filter,
                    },
                });
                this.addictions = addictions;
                this.summary = summary;
                this.pageCount = Math.ceil(summary.totalCount / this.limit);
            } catch (error) {
                console.error(error);
                if (error.response) alert(error.response.data.message);
            }
        },
        async setFilter({ name, sortKey }) {
            this.filter.code = name;
            this.filter.sortKey = sortKey;
            this.page = 1;
            await this.search();
        }
    },
    computed: {
        accessToken(){
            return this.$store.state.accessToken;
        },
        logon(){ 
            return !!this.accessToken 
        },
    }
};
</script>
