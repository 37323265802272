<template>
    <div>
        <head-tab tit="리커버">
            <v-tabs color="grey-9" hide-slider class="v-tabs--punch">
                <v-tab @click="setJob(null, 0)">All</v-tab>
                <v-tab v-for="job, index in jobs" :key="job._id" @click="setJob(job.code, index + 1)">{{ job?.name }}</v-tab>
            </v-tabs>
        </head-tab>

        <div v-if="filter.tab !== 0">
            <div class="mb-20 mb-lg-30">
                <v-row align="center" class="row--x-small">
                    <v-col>
                        <h3 class="tit">전문 리커버에게 문의하세요!</h3>
                    </v-col>
                    <!-- <v-col cols="auto">
                        <recover-filter />
                    </v-col> -->
                </v-row>
            </div>
            <v-row class="row--large row-cols-md-5 my-n10 my-lg-n30">
                <v-col v-for="recover in recovers" :key="recover._id" cols="6" class="py-10 py-lg-30">
                    <recover-item shadow :recover="{ ...recover.recover, _id: recover._id }"/>
                </v-col>
            </v-row>
        </div>
        <div v-else>
            <div v-for="job, index in jobs" :key="index" :class="index !== 0 ? 'mt-30 mt-lg-60' : ''">
                <div class="mb-20 mb-lg-30">
                    <v-row align="center" class="row--x-small">
                        <v-col>
                            <h3 class="tit">{{ job.name }}</h3>
                        </v-col>
                        <!-- <v-col cols="auto">
                            <recover-filter />
                        </v-col> -->
                    </v-row>
                </div>
                <v-row class="row--large row-cols-md-5 my-n10 my-lg-n30">
                    <v-col v-for="recover in allRecovers(job.code)" :key="recover._id" cols="6" class="py-10 py-lg-30">
                        <recover-item shadow :recover="{ ...recover.recover, _id: recover._id }"/>
                    </v-col>
                </v-row>
            </div>
        </div>

        <v-divider class="mt-30 mt-lg-60 mb-20 mb-lg-40" />

        <v-row class="row--x-small">
            <v-col cols="12" md="auto">
                <v-row class="row--x-small search--primary">
                    <v-col cols="12" md="auto">
                        <v-select v-model="filter.searchKey" :items="searchKeys" class="v-input--small w-100 w-md-120px" outlined hide-details></v-select>
                    </v-col>
                    <v-col cols="9" md="auto">
                        <v-text-field v-model="filter.searchValue" class="v-input--small w-100 w-md-240px" outlined hide-details placeholder="검색어를 입력하세요." />
                    </v-col>
                    <v-col cols="3" md="auto">
                        <v-btn class="h-100 w-100 min-w-md-60px" color="grey-6" @click="search(false)">
                            <span class="white--text font-size-14">검색</span>
                        </v-btn>
                    </v-col>
                </v-row>
            </v-col>
        </v-row>

        <div v-if="this.filter.tab !== 0" class="v-pagination-wrap">
            <v-pagination  :length="pageCount" :total-visible="11" @input="search()" />
        </div>
    </div>
</template>

<script>
import api from "@/api"
import HeadTab from "@/components/client/sub/head-tab.vue";
import RecoverItem from "@/components/client/recover/recover-item.vue";
import RecoverFilter from "@/components/client/recover/recover-filter.vue";

export default {
    components: {
        HeadTab,
        RecoverItem,
        RecoverFilter,
    },
    async mounted() {
        await this.init()
    },
    data() {
        return {

            page: 1,
            limit: 10,
            pageCount: 0,

            filter: {
                job: null,
                tab: 0,
                searchKey: "recover.name",
                searchValue: null
            },

            jobs: [],
            recovers: [],

            searchKeys: [
                { text: "이름", value: "recover.name" },
            ],
        }
    },
    methods: {
        async init() {
            let { jobs } = await api.v1.users.jobs.gets()
            this.jobs = jobs
            await this.search()
        },

        async search(){
        
            let { summary, recovers } = await api.v1.users.recovers({
                headers: {
                    skip: (this.page - 1) * this.limit,
                    limit: this.limit
                },
                params: this.filter
            });
        
            this.recovers = recovers;
            this.summary = summary;
            this.pageCount = Math.ceil(summary.totalCount / this.limit);
        },

        setJob(job, tab) {
            this.filter.job = job;
            this.filter.tab = tab;
            this.filter.searchValue = null;

            this.page = 1;
            this.search();
        },

        allRecovers(code) {
            return this.recovers.filter(el => {
                return el.recover.category === code;
            })
        }
    },
};
</script>

<style>

</style>