<template>
    <client-page>
        <section class="section--first section--last">
            <v-container>
                <!-- <board-list v-model="category" :code="code"/> -->
                <board-input />
                <!-- <board-view />        -->
            </v-container>
        </section>
    </client-page>
</template>

<script>
import api from "@/api";
import ClientPage from "@/pages/client/templates/ClientPage.vue";
import BoardList from "@/components/client/board/skin/column/list.vue";
import BoardInput from "@/components/client/board/skin/column/input.vue";
import BoardView from "@/components/client/board/skin/column/view.vue";

export default {
    components: {
        ClientPage,
        BoardList,
        BoardInput,
        BoardView,
    },

    data() {
        return {
            category: {},

            code: "column",
        };
    },
    async mounted() {
        // await this.init();
        if(!this.logon){
            alert("로그인 이후에 사용가능합니다");
            this.$router.push(`/login`);
            return;
        }
    },
    methods: {
        async init() {
            let { category } = await api.v1.boards.categories.get({ code: this.code });

            this.category = category;
        },
    },
    computed: {
        accessToken() {
            return this.$store.state.accessToken;
        },
        logon(){ 
            return !!this.accessToken 
        },
    },
};
</script>
