<template>
    <div>
        <v-btn outlined color="grey-d" class="filter-btn" @click="open()">
            <span class="grey-9--text">필터링</span>
            <i class="icon icon-filter ml-24 ml-lg-30"></i>
        </v-btn>
        <v-dialog v-model="show" max-width="520" width="100%">
            <div class="dialog__inner">
                <v-btn large tile absolute top right icon text color="black" @click="close">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
                <div class="dialog__contents">
                    <div>
                        <h4 class="tit tit--sm mb-10">정렬</h4>
                        <v-select placeholder="필터링" outlined hide-details class="v-input--small"/>
                    </div>
                    <div class="mt-12 mt-lg-24">
                        <h4 class="tit tit--sm mb-10">중독 유형</h4>
                        <v-btn-toggle class="v-btn-toggle--primary">
                            <div class="v-btn-wrap">
                                <v-btn outlined block rounded x-small color="primary">전체</v-btn>
                            </div>
                            <div class="v-btn-wrap">
                                <v-btn outlined block rounded x-small color="primary">성</v-btn>
                            </div>
                            <div class="v-btn-wrap">
                                <v-btn outlined block rounded x-small color="primary">마약</v-btn>
                            </div>
                            <div class="v-btn-wrap">
                                <v-btn outlined block rounded x-small color="primary">인터넷</v-btn>
                            </div>
                            <div class="v-btn-wrap">
                                <v-btn outlined block rounded x-small color="primary">알코올</v-btn>
                            </div>
                            <div class="v-btn-wrap">
                                <v-btn outlined block rounded x-small color="primary">도박</v-btn>
                            </div>
                        </v-btn-toggle>
                    </div>
                    <v-btn block large color="primary" class="mt-12 mt-lg-24">적용</v-btn>
                </div>
            </div>
        </v-dialog>
    </div>
</template>

<script>
export default {
    data() {
        return {
            show: false,
        }
    },
    methods:{
        open(){
            this.show = true;
        },
        close(){
            this.show = false;
        }
    }
}
</script>

<style lang="scss" scoped>
.filter-btn{
    &.v-btn:not(.v-btn--fab,.v-btn--icon).v-size--default{
        height: 40px;
        font-size: 1.4rem;
        padding: 0 10px 0 16px;
    }
}
.v-btn-toggle--primary{
    flex-wrap: wrap;
    display: flex;
    margin: -5px;
    .v-btn-wrap{
        padding: 5px;
        width: 33.33333%;
    }
}
::v-deep{
    .v-dialog{
        border-radius: 0;
        box-shadow: none;
    }
    .theme--light.v-btn-toggle:not(.v-btn-toggle--group) .v-btn.v-btn {
        border-color: var(--v-primary-base) !important;
        font-weight: 700;
        &.v-btn--active{
            background-color: var(--v-primary-base) !important;
            color: #fff !important;
        }
    }
}
.dialog{
    &__inner{
        position: relative;
        >.v-btn.v-btn--absolute.v-btn--top.v-btn--absolute.v-btn--right{
            top: 0;
            right: 0;
        }
    }
    &__contents{
        background-color: #fff;
        padding: 40px 20px;
    }
}
@media (min-width:576px){
}
@media (min-width:768px){
    .dialog{
        &__contents{
            padding: 60px 60px 100px;
        }
    }
}
@media (min-width:1024px){
}
@media (min-width:1200px){
}

</style>