var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('client-page', {
    staticClass: "app--main"
  }, [_c('section', {
    staticClass: "section section--last"
  }, [_c('v-container', [_c('div', {
    staticClass: "tit-wrap text-center"
  }, [_c('h2', {
    staticClass: "tit tit--lg"
  }, [_c('span', {
    style: `color: ${_vm.addiction.themeColor.hexa}`
  }, [_vm._v(_vm._s(_vm.addiction.subject))]), _vm._v(" 테스트 ")])]), _c('v-tabs-items', {
    attrs: {
      "touchless": ""
    },
    model: {
      value: _vm.tab,
      callback: function ($$v) {
        _vm.tab = $$v;
      },
      expression: "tab"
    }
  }, _vm._l(_vm.addiction.questions, function (question, index) {
    return _c('v-tab-item', {
      key: index
    }, [_c('addiction-question-view', {
      attrs: {
        "themeColor": _vm.addiction.themeColor.hexa,
        "questions": _vm.addiction.questions,
        "question": question,
        "index": index + 1
      },
      on: {
        "next": function (answer) {
          return _vm.answerNext(answer);
        }
      }
    })], 1);
  }), 1), _c('div', {
    staticClass: "mt-20 mt-lg-40"
  }, [_c('v-row', {
    staticClass: "row--small",
    attrs: {
      "justify": "center"
    }
  }, [_vm.tab !== 0 ? _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-btn', {
    attrs: {
      "icon": "",
      "color": "grey-6"
    },
    on: {
      "click": _vm.prev
    }
  }, [_c('i', {
    staticClass: "icon icon-left-arrow"
  })])], 1) : _vm._e(), _vm.tab !== _vm.addiction.questions.length - 1 ? _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-btn', {
    attrs: {
      "icon": "",
      "color": "grey-6"
    },
    on: {
      "click": _vm.next
    }
  }, [_c('i', {
    staticClass: "icon icon-right-arrow"
  })])], 1) : _vm._e()], 1)], 1)], 1)], 1)]);

}
var staticRenderFns = []

export { render, staticRenderFns }