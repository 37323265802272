<template>
    <client-page>
        <section class="section--first section--last">
            <v-container>
                <board-view />
            </v-container>
        </section>
    </client-page>
</template>

<script>
import ClientPage from "@/pages/client/templates/ClientPage.vue";
import BoardList from "@/components/client/board/skin/community/list.vue";
import BoardView from "@/components/client/board/skin/community/view.vue";
import BoardInput from "@/components/client/board/skin/community/input.vue";

export default {
    components: {
        ClientPage,
        BoardList,
        BoardView,
        BoardInput,
    },

    data() {
        return {};
    },
};
</script>
