var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('section', {
    staticClass: "section section--first section--last",
    attrs: {
      "id": "qna"
    }
  }, [_c('v-container', [_c('h3', {
    staticClass: "tit mb-16"
  }, [_vm._v("QnA")]), _c('v-divider'), _c('div', {
    staticClass: "pa-12 pa-lg-20 grey-f7"
  }, [_c('v-checkbox', {
    staticClass: "mb-16",
    attrs: {
      "hide-details": "",
      "label": "비밀 글"
    },
    model: {
      value: _vm.question.isPassword,
      callback: function ($$v) {
        _vm.$set(_vm.question, "isPassword", $$v);
      },
      expression: "question.isPassword"
    }
  }), _c('v-row', {
    staticClass: "row--x-small",
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', [_c('v-textarea', {
    attrs: {
      "rows": "4",
      "placeholder": "질문을 입력하세요.",
      "outlined": "",
      "hide-details": ""
    },
    model: {
      value: _vm.question.content,
      callback: function ($$v) {
        _vm.$set(_vm.question, "content", $$v);
      },
      expression: "question.content"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-btn', {
    attrs: {
      "small": "",
      "text": "",
      "color": "grey-1"
    },
    on: {
      "click": _vm.save
    }
  }, [_vm._v(" 등록하기 ")])], 1)], 1)], 1), _c('v-divider'), _vm._l(_vm.questions, function (question) {
    return _c('div', {
      key: question._id
    }, [_c('div', {
      staticClass: "pa-12 pa-lg-20"
    }, [_c('v-row', [_c('v-col', {
      attrs: {
        "cols": "auto"
      }
    }, [_c('div', {
      staticClass: "qna__mark accent"
    }, [_vm._v("Q")])]), _c('v-col', [_c('v-card', {
      attrs: {
        "rounded": "",
        "color": "grey-fa"
      }
    }, [_c('div', {
      staticClass: "pa-12 pa-lg-20"
    }, [_c('v-row', {
      staticClass: "row--x-large",
      attrs: {
        "align": "center"
      }
    }, [_c('v-col', [_c('v-row', {
      staticClass: "row--x-small"
    }, [_c('v-col', {
      attrs: {
        "cols": "auto"
      }
    }, [_c('board-user-level', {
      attrs: {
        "user": question === null || question === void 0 ? void 0 : question.user,
        "textStyle": "page-text grey-6--text"
      }
    })], 1), _c('v-col', {
      attrs: {
        "cols": "auto"
      }
    }, [_c('p', {
      staticClass: "page-text grey-6--text"
    }, [_vm._v(_vm._s(_vm.$dayjs(question === null || question === void 0 ? void 0 : question.createdAt).format("YYYY. MM. DD")))])])], 1), _c('p', {
      staticClass: "page-text line-height-15 mt-10",
      domProps: {
        "innerHTML": _vm._s(question === null || question === void 0 ? void 0 : question.content)
      }
    })], 1), !(question !== null && question !== void 0 && question.reply) ? _c('v-col', {
      attrs: {
        "cols": "12",
        "sm": "auto"
      }
    }, [_c('div', {
      staticClass: "pa-md-24"
    }, [_c('v-btn', {
      staticClass: "font-weight-bold",
      attrs: {
        "x-small": "",
        "rounded": "",
        "dark": "",
        "color": "grey-6"
      },
      on: {
        "click": function ($event) {
          return _vm.setQuestion(question);
        }
      }
    }, [_vm._v("답변하기")])], 1)]) : _vm._e()], 1)], 1)])], 1)], 1), question.reply ? _c('div', {
      staticClass: "mt-6"
    }, [_c('v-row', [_c('v-col', {
      attrs: {
        "cols": "auto"
      }
    }, [_c('div', {
      staticClass: "qna__mark primary"
    }, [_vm._v("A")])]), _c('v-col', [_c('v-card', {
      attrs: {
        "rounded": "",
        "color": "primary-50"
      }
    }, [_c('div', {
      staticClass: "pa-12 pa-lg-20"
    }, [_c('v-row', {
      staticClass: "row--x-small"
    }, [_c('v-col', {
      attrs: {
        "cols": "auto"
      }
    }, [_c('board-user-level', {
      attrs: {
        "user": question === null || question === void 0 ? void 0 : question.recover,
        "textStyle": "page-text grey-6--text"
      }
    })], 1), _c('v-col', {
      attrs: {
        "cols": "auto"
      }
    }, [_c('p', {
      staticClass: "page-text grey-6--text"
    }, [_vm._v(_vm._s(_vm.$dayjs(question === null || question === void 0 ? void 0 : question.createdAt).format("YYYY. MM. DD")))])])], 1), _c('p', {
      staticClass: "page-text line-height-15 mt-10",
      domProps: {
        "innerHTML": _vm._s(question === null || question === void 0 ? void 0 : question.reply)
      }
    })], 1)])], 1)], 1)], 1) : _vm._e()], 1), question._id === _vm.editQuestion._id ? _c('div', [_c('v-divider'), _c('div', {
      staticClass: "pa-12 pa-lg-20 grey-f7"
    }, [_c('v-checkbox', {
      staticClass: "mb-16",
      attrs: {
        "hide-details": "",
        "label": "비밀 글"
      },
      model: {
        value: _vm.editQuestion.isPassword,
        callback: function ($$v) {
          _vm.$set(_vm.editQuestion, "isPassword", $$v);
        },
        expression: "editQuestion.isPassword"
      }
    }), _c('v-row', {
      staticClass: "row--x-small",
      attrs: {
        "align": "center"
      }
    }, [_c('v-col', [_c('v-textarea', {
      attrs: {
        "rows": "4",
        "placeholder": "답변을 입력하세요.",
        "outlined": "",
        "hide-details": ""
      },
      model: {
        value: _vm.editQuestion.reply,
        callback: function ($$v) {
          _vm.$set(_vm.editQuestion, "reply", $$v);
        },
        expression: "editQuestion.reply"
      }
    })], 1), _c('v-col', {
      attrs: {
        "cols": "auto"
      }
    }, [_c('v-btn', {
      attrs: {
        "small": "",
        "text": "",
        "color": "grey-1"
      },
      on: {
        "click": _vm.edit
      }
    }, [_vm._v(" 등록하기 ")])], 1)], 1)], 1)], 1) : _vm._e(), _c('v-divider')], 1);
  }), _c('div', {
    staticClass: "v-pagination-wrap"
  }, [_c('v-pagination', {
    attrs: {
      "length": _vm.pageCount,
      "total-visible": 11
    },
    on: {
      "input": _vm.init
    },
    model: {
      value: _vm.page,
      callback: function ($$v) {
        _vm.page = $$v;
      },
      expression: "page"
    }
  })], 1)], 2)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }